

























































































import {Component, Vue} from "vue-property-decorator";
import TableSelectableItem from "@/components/Atoms/TableSelectableItem.vue";
import {Task} from "@/models/interfaces/Task";
import {TaskModel} from "@/models/TaskModel";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";

/**
 * @summary タスクページを表示するためのビューを提供します.
 */
@Component({
    components: {
        TableSelectableItem
    }
})
export default class AddResponseLogDialog extends Vue {
    private resolve?: (value: TaskModel | undefined | PromiseLike<TaskModel | undefined>) => void = undefined;
    private task = new TaskModel({}, {});
    private isOpend = false;

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * @summary タスクを追加するためのダイアログを開きます.
     * @param Task タスク
     * @returns 追加する内容
     */
    public showAsync(task: TaskModel): Promise<TaskModel | undefined> {
        this.isOpend = true;
        this.task = task;
        return new Promise<TaskModel | undefined>(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * @summary キャンセルしてダイアログを閉じます.
     */
    private cancel(): void {
        this.isOpend = false;
        this.resolve!(undefined);
    }

    /**
     * @summary ダイアログを閉じて追加するタスクを取得します.
     */
    private ok() {
        this.isOpend = false;
        this.resolve!(this.task);
    }

    private toArray<TValue>(dictionary: {[key: number]: TValue}): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }
}
