














import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class NotifySnackBar extends Vue {
    private isShow = false;
    private notifyText = "";
    private notifyColor = "";
    private timeoutSeconds: Number = 6000;

    public show(notifyText: string, notifyColor: string, timeoutSeconds: Number = 6000): void {
        this.notifyText = notifyText;
        this.notifyColor = notifyColor;
        this.timeoutSeconds = timeoutSeconds;
        this.isShow = true;
    }
}
