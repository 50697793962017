const holidays = [
    {
        "2018-01-01": "元日",
        "2018-01-08": "成人の日",
        "2018-02-11": "建国記念の日",
        "2018-02-12": "建国記念の日 振替休日",
        "2018-03-21": "春分の日",
        "2018-04-29": "昭和の日",
        "2018-04-30": "昭和の日 振替休日",
        "2018-05-03": "憲法記念日",
        "2018-05-04": "みどりの日",
        "2018-05-05": "こどもの日",
        "2018-07-16": "海の日",
        "2018-08-11": "山の日",
        "2018-09-17": "敬老の日",
        "2018-09-23": "秋分の日",
        "2018-09-24": "秋分の日 振替休日",
        "2018-10-08": "体育の日",
        "2018-11-03": "文化の日",
        "2018-11-23": "勤労感謝の日",
        "2018-12-23": "天皇誕生日",
        "2018-12-24": "天皇誕生日 振替休日"
    },
    {
        "2019-01-01": "元日",
        "2019-01-14": "成人の日",
        "2019-02-11": "建国記念の日",
        "2019-03-21": "春分の日",
        "2019-04-29": "昭和の日",
        "2019-04-30": "祝日",
        "2019-05-01": "天皇の即位の日",
        "2019-05-02": "祝日",
        "2019-05-03": "憲法記念日",
        "2019-05-04": "みどりの日",
        "2019-05-05": "こどもの日",
        "2019-05-06": "こどもの日 振替休日",
        "2019-07-15": "海の日",
        "2019-08-11": "山の日",
        "2019-08-12": "山の日 振替休日",
        "2019-09-16": "敬老の日",
        "2019-09-23": "秋分の日",
        "2019-10-14": "体育の日",
        "2019-10-22": "即位礼正殿の儀の行われる日",
        "2019-11-03": "文化の日",
        "2019-11-04": "文化の日 振替休日",
        "2019-11-23": "勤労感謝の日"
    },
    {
        "2020-01-01": "元日",
        "2020-01-13": "成人の日",
        "2020-02-11": "建国記念の日",
        "2020-02-23": "天皇誕生日",
        "2020-02-24": "天皇誕生日 振替休日",
        "2020-03-20": "春分の日",
        "2020-04-29": "昭和の日",
        "2020-05-03": "憲法記念日",
        "2020-05-04": "みどりの日",
        "2020-05-05": "こどもの日",
        "2020-05-06": "憲法記念日 振替休日",
        "2020-07-23": "海の日",
        "2020-07-24": "体育の日",
        "2020-08-10": "山の日",
        "2020-09-21": "敬老の日",
        "2020-09-22": "秋分の日",
        "2020-11-03": "文化の日",
        "2020-11-23": "勤労感謝の日"
    }
];

export default holidays;
