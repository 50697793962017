import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Task} from "../models/interfaces/Task";
import {TaskModel} from "../models/TaskModel";
import WorkLoadCalculator from "../models/WorkLoadCalculator";
import store from "../store";
import {TaskSearchParamModel} from "@/models/TaskSearchParamModel";

const colors = [
    "#9c27b0",
    "#4527A0",
    "#673AB7",
    "#2196f3",
    "#03A9F4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8BC34A",
    "#CDDC39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#f44336",
    "#ec407a"
];

/**
 * @summary タスク情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "task", namespaced: true})
export class TaskStore extends VuexModule {
    // #region private fields
    private _tasks: TaskModel[] = [];
    private _selectedTaskIdList: number[] = [];
    private workLoadCalculator = new WorkLoadCalculator();
    // #endregion

    // #region public getters
    /**
     * @summary タスクリスト
     */
    public get tasks(): TaskModel[] {
        return this._tasks;
    }

    /**
     * @summary 選択状態のタスクIDのリストを取得する
     */
    public get selectedTaskIdList(): number[] {
        return this._selectedTaskIdList;
    }
    // #endregion

    // #region public mutations
    /**
     * @summary タスクリストをセットします.
     * @param tasks タスクリスト
     */
    @Mutation
    public setTasks(tasks: TaskModel[]): void {
        this._tasks = tasks;
    }

    /**
     * @summary タスクを選択状態にします
     * @param {number} taskId タスクID
     */
    @Mutation
    public selectTask(taskId: number): void {
        this._selectedTaskIdList = [taskId];
    }

    /**
     * @summary 選択状態のタスクを追加します
     * @param {number} taskId タスクID
     */
    @Mutation
    public addSelectTask(taskId: number): void {
        if (this._selectedTaskIdList.indexOf(taskId) >= 0) {
            return;
        }

        this._selectedTaskIdList.push(taskId);
    }

    /**
     * @summary タスクを未選択状態にします
     * @param {number} taskId タスクID
     */
    @Mutation
    public unselectTask(taskId: number): void {
        this._selectedTaskIdList = this._selectedTaskIdList.filter(selectedTaskId => selectedTaskId !== taskId);
    }
    // #endregion

    // #region actions
    /**
     * @summary タスク情報をサーバーから取り込みます.
     */
    @Action
    public async fetchTasks(searchParam: TaskSearchParamModel): Promise<void> {
        try {
            const response = await axios.get<Task[]>("api/task" + searchParam.getQueryString());
            const tasks: TaskModel[] = [];
            for (const item of response.data) {
                tasks.push(new TaskModel(item, {
                    startDate: item.developStartDate !== ""
                        ? new Date(item.developStartDate)
                        : undefined,
                    completeDate: item.developStartDate !== ""
                        ? this.workLoadCalculator.getCompleteDate(
                            new Date(item.developStartDate),
                            item.cost)
                        : undefined
                }));
            }
            this.setTasks(tasks);
        }
        catch (ex) {
            console.error("タスクの取り込みに失敗しました.", ex);
        }
    }

    /**
     * @summary タスク情報を追加します.
     * @param newTask 追加するタスク情報
     */
    @Action
    public async addTask(newTask: TaskModel): Promise<boolean> {
        const response = await axios.post("api/task", newTask);
        if (response.data) {
            this._tasks.push(response.data);

            return true;
        }
        else {
            return false;
        }
    }

    /**
     * @summary タスク情報を保存します.
     * @param task 保存するタスク情報
     */
    @Action
    public async saveTask(task: TaskModel): Promise<boolean> {
        try {
            const response = await axios.put("api/task/" + task.id, task);
            return true;
        }
        catch {
            console.error("タスクの保存に失敗しました.");
            return false;
        }
    }

    /**
     * @summary タスク情報を削除します.
     * @param task 削除するタスク情報
     */
    @Action
    public async deleteTask(task: TaskModel): Promise<boolean> {
        const response = await axios.delete("api/task/" + task.id);
        if (response.data) {
            this.setTasks(this.tasks.filter(oldTask => oldTask.id !== task.id));
            return true;
        }
        else {
            return false;
        }
    }
    // #endregion
}

export const TaskStoreModule = getModule(TaskStore);
