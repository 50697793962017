
























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class DeleteDialog extends Vue {
    private isShow = false;
    private dialogTitle = "";
    private dialogText = "";
    private resolve?: (value: PromiseLike<boolean> | boolean) => void = undefined;

    public showAsync(dialogTitle: string, dialogText: string): Promise<boolean> {
        this.dialogTitle = dialogTitle;
        this.dialogText = dialogText;
        this.isShow = true;
        return new Promise<boolean>(resolve => {
            this.resolve = resolve;
        });
    }

    private cancel() {
        this.isShow = false;
        if (this.resolve) {
            this.resolve(false);
        }
    }

    private ok() {
        this.isShow = false;
        if (this.resolve) {
            this.resolve(true);
        }
    }
}
