import holidays from "./holiday";

/**
 * @summary 工数と日付に関する算出機能を提供します.
 * @description
 */
export default class WorkLoadCalclator {
    // #region private fields
    public holidays: {[key: string]: Date;} = {};
    // #endregion

    // #region public methods
    /**
     * @summary コンストラクタ
     */
    constructor() {
        holidays.forEach((x) => {
            for (const key in x) {
                const date = new Date(key);
                this.holidays[date.toLocaleDateString()] = date;
            }
        });
    }

    /**
     * @summary 指定した日付が祝日かどうかを取得します.
     * @param dateStr 日付（例：2019/10/02）
     */
    public isHoliday(dateStr: string): boolean {
        return !!this.holidays[dateStr] ;
    }

    /**
     * @summary 指定した期間内に祝日が何日あるかを算出します.
     * @param startDate 開始日
     * @param endDate 終了日
     * @returns 祝日の数
     */
    public getHolidayCount(startDate: Date, endDate: Date): number {
        let count = 0;
        const holidays = this.holidays;
        for (const key in holidays) {
            if (startDate <= holidays[key] && holidays[key] <= endDate) {
                count++;
            }
        }
        return count;
    }

    /**
     * @summary 指定した工数から土日祝を含めた終了予定日を算出します.
     * @param startDate 開始日
     * @param workLoad 工数
     * @returns 実際の日数
     */
    public getCompleteDate(startDate: Date, workLoad: number): Date {
        if (typeof (workLoad) === "string") {
            throw new Error("workLoad must be Number.");
        }

        if (workLoad === 0) {
            return new Date(startDate);
        }

        // 土日祝および休日の数
        let holidayCount = this.getSaturdayAndSundayCount(startDate, workLoad);

        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + holidayCount + (workLoad - 1));
        // 祝日を加算
        const add = this.getHolidayCount(startDate, endDate);
        holidayCount += add;
        // 祝日を追加した際にまたぐ土日の数を追加
        holidayCount += this.getSaturdayAndSundayCount(endDate, add);
        const completeDate = new Date(startDate);
        completeDate.setDate(completeDate.getDate() + (workLoad - 1) + holidayCount);
        return completeDate;
    }

    public getWorkLoad(startDate: Date, completeDate: Date): number {
        const diff = this.getDateDiff(startDate, completeDate);
        const holidayCount = this.getHolidayCount(startDate, completeDate);
        const weekEndCount = Math.floor(((diff) + (startDate.getDay() - 1)) / 7) * 2;
        return (diff + 1) - holidayCount - weekEndCount;
    }

    /**
     * @summary 指定した区間の日数の差分を算出します.
     * @param date1 開始日
     * @param date2 終了日
     * @return 何日あるか
     */
    public getDateDiff(date1: Date, date2: Date): number {
        return (date2.getTime() - date1.getTime()) / 86400000;
    }

    /**
     * @summary 指定した区間の月数の差分を算出します.
     * @param date1 開始日
     * @param date2 終了日
     * @return 何か月あるか
     */
    public getMonthDiff(date1: Date, date2: Date) {
        let months = 0;
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth() + 1;
        months += date2.getMonth() + 1;
        return months <= 0 ? 0 : months;
    }

    /**
     * @summary 指定した月の末日が何日であるかを算出します
     * @param date 算出したい月
     * @returns 何日まであるか
     */
    public getDayCountOfMonth(date: Date): number {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }
    // #endregion

    // #region private methods
    /**
     * @summary 指定した日付から指定した工数で作業する場合終了までの間に存在する土曜日と日曜日の数を加算して取得します.
     * @param startDate 開始日
     * @param workLoad 工数
     * @returns 存在する土曜日と日曜日の数を加算した値
     */
    public getSaturdayAndSundayCount(startDate: Date, workLoad: number): number {
        return Math.floor((workLoad + this.getDayOffset(startDate.getDay())) / 5) * 2;
    }

    private getDayOffset(day: number): number {
        if (day > 0) {
            return day - 1 - 1;
        }
        return 0;
    }
    // #endregion
}
