















































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {TaskModel} from "@/models/TaskModel";
import {TaskStore, TaskStoreModule} from "@/stores/TaskStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import DeleteDialog from "@/components/Atoms/DeleteDialog.vue";
import NotifySnackBar from "@/components/Atoms/NotifySnackBar.vue";
import TableSelectableItem from "@/components/Atoms/TableSelectableItem.vue";
import TableTextFieldDialog from "@/components/Atoms/TableTextFieldDialog.vue";
import TableTextareaDialog from "@/components/Atoms/TableTextareaDialog.vue";
import TableDatePickerDialog from "@/components/Atoms/TableDatePickerDialog.vue";
import AddTaskDialog from "@/components/Organisms/AddTaskDialog.vue";
import TaskThreadView from "@/components/Templates/TaskThreadView.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {IdAndName} from "../../models/interfaces/IdAndName";
import * as CONFIG from "@/Config";
import {TaskSearchParamModel} from "../../models/TaskSearchParamModel";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";
import TaskSearchForm from "@/components/Organisms/TaskSearchForm.vue";
import ConfirmDialog from "@/components/Atoms/ConfirmDialog.vue";
import moment from "moment";
import {VDataTableOptions} from "../../models/interfaces/VDataTableOptions";

@Component({
    components: {
        DeleteDialog,
        NotifySnackBar,
        TableSelectableItem,
        TableTextFieldDialog,
        TableTextareaDialog,
        TableDatePickerDialog,
        AddTaskDialog,
        TaskThreadView,
        NameById,
        TaskSearchForm,
        ConfirmDialog
    }
})
export default class TaskListPage extends Vue {
    private ipTmp = "";
    public saveNotifySnack = false;
    public saveNotifySnackColor = "";
    public saveNotifySnackText = "";
    private tableHeight = 0;
    private isLoading = false;
    private textFieldContent = "";
    private isOpend = true;
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private searchParams: TaskSearchParamModel = CONFIG.DefaultTaskSearchParam();
    private isShowSearchPanel = false;
    private openedThreadId = 0;
    public headers = [
        {text: "", value: "action", sortable: false, width: 26},
        {text: "No", value: "id", width: 72},
        {text: "システム", value: "systemId", width: 100},
        {text: "ステータス", value: "statusId", width: 100},
        {text: "大項目", value: "category", width: 100},
        {text: "詳細", value: "detail", sortable: false, width: 240},
        {text: "優先度", value: "priority", width: 80},
        {text: "公開日", value: "releaseDate", width: 100},
        {text: "担当者名", value: "personId", width: 100},
        {text: "備考", value: "description", sortable: false, width: 350},
        {text: "仕様", value: "specification", sortable: false, width: 350},

        {text: "", value: "action2", sortable: false, width: 40}
    ];

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get taskStore(): TaskStore {
        return TaskStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private async created(): Promise<void> {
        const json = localStorage.getItem("sortBy_task");
        if (json) {
            this.sortBy = JSON.parse(json);
        }

        const sortDescJson = localStorage.getItem("sortDesc_task");
        if (sortDescJson) {
            this.sortDesc = JSON.parse(sortDescJson);
        }

        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 128;
        });
        this.tableHeight = window.innerHeight - 128;

        this.isLoading = true;
        this.loadSearchParam();
        await this.taskStore.fetchTasks(this.searchParams);
        this.isLoading = false;
    }

    private loadSearchParam(): void {
        const json = localStorage.getItem("taskList_searchParam");
        if (json) {
            Object.assign(this.searchParams, JSON.parse(json));
        }
        else {
            this.searchParams = CONFIG.DefaultTaskSearchParam();
        }

        this.searchParams.sheetId = this.sheetStore.currentSheetId;
    }

    private saveSearchParam(): void {
        localStorage.setItem("taskList_searchParam", JSON.stringify(this.searchParams));
    }

    private onUpdateOptions(options: Partial<VDataTableOptions>): void {
        this.sortBy = [];
        if (options.sortBy) {
            this.sortBy = options.sortBy;
            localStorage.setItem("sortBy_task", JSON.stringify(options.sortBy));
        }

        this.sortDesc = [];
        if (options.sortDesc) {
            this.sortDesc = options.sortDesc;
            localStorage.setItem("sortDesc_task", JSON.stringify(options.sortDesc));
        }
    }

    private openNotifySnackBar(text: string, color: string, timeout = 6000) {
        const notifySnackBar = this.$refs.notifySnackBar as NotifySnackBar;
        if (!notifySnackBar) {
            return;
        }

        notifySnackBar.show(text, color, timeout);
    }

    public async addTask(): Promise<void> {
        const addTaskDialog = this.$refs.addTaskDialog as AddTaskDialog;
        if (!addTaskDialog) {
            return;
        }

        const task = await addTaskDialog.showAsync(new TaskModel({}, {}));
        if (!task) {
            return;
        }

        if (this.sheetStore.currentSheetId) {
            task.sheetId = this.sheetStore.currentSheetId;
        }

        this.isLoading = true;
        const result = await this.taskStore.addTask(task);
        this.isLoading = false;
        if (result) {
            this.openNotifySnackBar("登録しました", "success");
        }
        else {
            this.openNotifySnackBar("登録に失敗しました", "error");
        }
    }

    public async saveTask(task: TaskModel): Promise<void> {
        this.isLoading = true;
        const result = await this.taskStore.saveTask(task);
        this.isLoading = false;
        if (result) {
            this.openNotifySnackBar("更新しました", "success");
        }
        else {
            this.openNotifySnackBar("更新に失敗しました", "error");
        }
    }

    private async openReleaseDateEdit(event: MouseEvent, task: TaskModel): Promise<void> {
        const inputReleaseDate = await this.openDatePickerDialog(event, task.releaseDate).catch(err => void (err));
        if (!inputReleaseDate) {
            return;
        }

        if (!task.developEndDate) {
            task.releaseDate = inputReleaseDate;
            this.saveTask(task);
            return;
        }

        const releaseDate = moment(inputReleaseDate, "YYYY-MM-DD");
        const developEndDate = moment(task.developEndDate, "YYYY-MM-DD");

        if (releaseDate.isAfter(developEndDate)) {
            task.releaseDate = inputReleaseDate;
            this.saveTask(task);
        }
        else {
            const dialog = this.$refs.confirmDialog as ConfirmDialog;
            if (!dialog) {
                return;
            }

            const confirmResult = await dialog.showAsync(
                "公開日変更確認",
                "公開日は開発開始日より後でなければいけません。\n入力された公開日：" + inputReleaseDate.split("-").join("/") + "\n開発終了日：" + task.developEndDate.split("-").join("/") + "\nどうしますか？",
                "わかりました。再度入力します",
                "公開日の変更をキャンセルします"
            );

            if (!confirmResult) {
                return;
            }

            // ダイアログを再度表示
            this.openReleaseDateEdit(event, task);
        }
    }

    private async deleteTask(task: TaskModel): Promise<void> {
        const deleteDialog = this.$refs.deleteDialog as DeleteDialog;
        if (!deleteDialog) {
            return;
        }

        this.isLoading = true;
        const isDelete = await deleteDialog.showAsync(`No.${task.id}`, "を削除しますか？");
        this.isLoading = false;
        if (isDelete) {
            const result = await this.taskStore.deleteTask(task);
            if (result) {
                this.openNotifySnackBar("削除しました", "success");
            }
            else {
                this.openNotifySnackBar("削除に失敗しました", "error");
            }
        }
    }

    private async openDatePickerDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.datePickerDialog as TableDatePickerDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextFieldDialog(event: MouseEvent, content: string): Promise<string | Error> {
        const dialog = this.$refs.textFieldDialog as TableTextFieldDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextareaDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textAreaDialog as TableTextareaDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openSelectableItem(event: MouseEvent, items: {[key: number]: IdAndName}, values?: {id: number, name: string}[]): Promise<number | undefined> {
        const dialog = this.$refs.selectableItem as TableSelectableItem;
        if (!dialog) {
            return undefined;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return undefined;
        }

        if (!items) {
            items = [{id: 0, name: ""}];
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(items, rect.left, rect.top, values);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async showTaskThread(task: TaskModel): Promise<void> {
        const taskThreadView = this.$refs.taskThreadView as TaskThreadView;
        if (taskThreadView && task) {
            // 選択状態にする
            this.selectTask(task.id);

            if (this.openedThreadId === task.id) {
                this.openedThreadId = 0;
                taskThreadView.close();
            }
            else {
                this.openedThreadId = task.id;
                taskThreadView.showAsync(task);
            }
        }
    }

    private async submitSearch(): Promise<void> {
        this.isLoading = true;
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.taskStore.fetchTasks(this.searchParams);
        this.saveSearchParam();
        this.isLoading = false;
    }

    private resetSearchDialog(): void {
        this.searchParams = CONFIG.DefaultTaskSearchParam();
    }

    private systemColClass(): string {
        return "system-col";
    }

    private selectTask(taskId: number): void {
        if (this.taskStore.selectedTaskIdList.indexOf(taskId) >= 0) {
            this.taskStore.unselectTask(taskId);
        }
        else {
            this.taskStore.selectTask(taskId);
        }
    }
}
